import React from "react";
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SectionTitle from '../../components/sectiontitle.jsx';
import Section from '../../components/section.jsx';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import AP1 from '../../images/portfolio/adhdpro/adhdpro-1.png';
import AP2 from '../../images/portfolio/adhdpro/adhdpro-2.png';
import AP3 from '../../images/portfolio/adhdpro/adhdpro-3.png';
import AP4 from '../../images/portfolio/adhdpro/adhdpro-4.png';


function PortfolioLenderDB() {
  return (
    <Layout currentPage={[{title:"Portfolio", url:"portfolio"}, {title:"ADHD Pro", url:"/portfolio/adhdpro"}]}>
      <SEO
        keywords={[`Robert Merki`, `Robmerki`, `Rob Merki`, `@robmerki`,`Merki.ca`]}
        title="ADHD Pro"
      />

      
      <Section>
        <SectionTitle title="ADHD Pro" />
      
        <p>
          <em>ADHD Pro</em> is a book I wrote in 2020 about adult ADHD.
        </p>
        <p>
          My own journey with understanding my brain led me to realize that massive gap in practical strategies for adult professionals with ADHD. I wrote ADHD Pro based on the dozens of interviews I did was successful people with ADHD, and laid out a framework for others for how they can be productive despite their ADHD diagnosis.
        </p>
        <p>
          I initially published it on Gumroad, but have sinced produced an Amazon Kindle, paperback, and audiobook version. I recorded the audiobook myself.
        </p>

        <p>
          After much hesitation, I was convinced by some friends to start an accompanying <a href="https://www.tiktok.com/@adhdpro.xyz">TikTok account</a>. I have since gained 50,000+ followers in two months.
        </p>

        <p>
          ADHD Pro has sold over 1,000 copies.
        </p>

        <p>
          More info about the book: <a href="https://adhdpro.xyz">adhdpro.xyz</a>
        </p>

        <SectionTitle title="Tech" />
        <p>
          I wrote the book in Microsoft Word, made all the graphics in Figma, and created the audiobook with Audacity.
        </p>
        
        <p>
          The landing page was built with GatsbyJS &amp; TailwindCSS, and hosted on Netlify.
        </p>
        <SectionTitle title="Images" />

        <Zoom><img className="w-full h-auto" src={AP1}/></Zoom>
        <Zoom><img className="w-full h-auto" src={AP2}/></Zoom>
        <Zoom><img className="w-full h-auto" src={AP3}/></Zoom>
        <Zoom><img className="w-full h-auto" src={AP4}/></Zoom>
        

      </Section>
      
    </Layout>
  );
}


export default PortfolioLenderDB;